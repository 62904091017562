import Card from "./Card";
import { useEffect, useState } from "react";
import ManualSetup from "./ManualSetup";
import SignIn from "./SignIn";
import toast from "react-hot-toast";
import robot from "../assets/robot.json";
import Lottie from "lottie-react";
import * as OTPAuth from "otpauth";
import { useLocation } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

export default function Guide() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [manualSetup, setManualSetup] = useState(false);
  const [code, setCode] = useState("");
  const [codeValid, setCodeValid] = useState(false);
  const [TOTP, setTOTP] = useState("");

  const location = useLocation();

  function getTOTP() {
    let TOTP = new OTPAuth.TOTP({
      issuer: "Amazon",
      label: "FreebieFlow",
      algorithm: "SHA1",
      digits: 6,
      period: 30,
      secret: code.replaceAll(" ", ""),
    });
    setTOTP(TOTP.generate());
  }

  useEffect(() => {
    if (codeValid) {
      getTOTP();
    }
  }, [codeValid]);

  const goForward = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const goBackward = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const steps = [
    {
      title: "Let's get started",
      description:
        "This process takes about 10 minutes, but if you follow the instructions correctly you’ll be receiving boxes very soon!",
      first: true,
      extra: (
        <div className="w-full rounded-3xl border p-5 shadow">
          <div className="mb-5 flex items-center gap-1.5 font-bold">
            <FaInfoCircle /> Amazon 2FA Secret Keys look like this:
          </div>
          <strong className="text-sm font-bold uppercase tracking-wide opacity-75">
            Example key
          </strong>
          <p className="my-0 text-sm font-medium">
            HBRQ JX7P TKYP ZV3X N6UK NGFB NISW H5MM HFTM VWWO NGWO SEJA
          </p>
        </div>
      ),
      desktopVideo: "",
      mobileVideo: "",
    },
    {
      title: "Ensure 2-factor authentication is disabled",
      list: [
        <p className="my-0">
          Go to{" "}
          <a href="https://www.amazon.com/a/settings/approval" target="_blank">
            this link
          </a>
        </p>,
        "Click the disable button",
        "Check the checkbox",
        "Click disable",
      ],
      desktopVideo: "/videos/desktop/ensure.mp4",
      mobileVideo: "/videos/mobile/ensure.mp4",
    },
    {
      title: "Set up 2FA",
      list: [
        'Click the yellow "Get Started" button',
        "Select the Authenticator app option",
        "Click 'Can't Scan The Barcode?' (only on desktop)",
        "Copy your 2FA Secret Key",
        "Paste your 2FA Secret Key in a safe place!",
      ],
      extra: (
        // <input
        //   className="w-full rounded border px-4 py-2 shadow-sm"
        //   placeholder="Paste your 2FA code here..."
        //   value={code}
        //   onChange={(e) => {
        //     if (e.target.value.match(/^[A-Z2-7]{32,64}$/)) {
        //       setCodeValid(true);
        //       setCode(e.target.value);
        //     } else {
        //       setCode("");
        //       setCodeValid(false);
        //       toast.error("You must paste a valid 2FA code");
        //     }
        //   }}
        // />
        <div className="flex items-center gap-2.5 rounded-full border border-blue-200 bg-blue-50 px-4 py-2.5 text-sm font-medium text-blue-500">
          <FaInfoCircle /> Your 2FA Secret Key is important! Save it for later.
        </div>
      ),
      codeValid: codeValid,
      desktopVideo: "/videos/desktop/setup.mp4",
      mobileVideo: "/videos/mobile/setup.mp4",
    },
    {
      title: "Download Google Authenticator",
      description: (
        <>
          {/* Mobile */}
          <ol className="lg:hidden">
            <li>Get the “Google Authenticator” app on your phone</li>
            <li>Click the “+” icon to add a code</li>
            <li>Select “enter a setup key”</li>
            <li>In the account box type “Amazon”</li>
            <li>In the key box paste 2FA Secret Key</li>
            <li>Press add code</li>
          </ol>
          {/* Desktop */}
          <ol className="hidden lg:block">
            <li>Get the “Google Authenticator” app on your phone</li>
            <li>Click the “+” icon to add a code</li>
            <li>Select “Scan a QR code”</li>
            <li>Scan the QR on your Amazon page</li>
          </ol>
        </>
      ),
      desktopVideo: "/videos/desktop/gauth.mp4",
      mobileVideo: "/videos/mobile/gauth.mp4",
    },
    // {
    //   title: "Complete 2-step setup",
    //   description: (
    //     <button
    //       onClick={() => {
    //         navigator.clipboard.writeText(TOTP);
    //         toast.success("Copied to clipboard!");
    //       }}
    //       className="mt-2.5 flex w-full justify-center rounded-2xl border p-2.5 shadow"
    //     >
    //       <strong className="text-4xl">{TOTP}</strong>
    //     </button>
    //   ),
    //   list: [
    //     "Copy the code above (just click it!)",
    //     "Switch back to your Amazon tab",
    //     "Paste the code into the 2FA code box",
    //     'Click "Verify OTP and Continue"',
    //   ],
    // },
    {
      title: "Complete 2-step setup",
      list: [
        "You will now see a 6 digit code generated in your authenticator app that changes every 30 seconds",
        "Enter 6 digit code into the box on Amazon",
        "Select “Verify OTP and continue”",
        "Click checkbox “don’t require OTP”",
        "Select “Got it. Turn on Two-Step Verification”",
      ],
      desktopVideo: "/videos/desktop/2step.mp4",
      mobileVideo: "/videos/mobile/2step.mp4",
      extra: (
        <div className="flex flex-row items-center gap-2.5 rounded-lg border border-blue-200 bg-blue-50 px-4 py-2.5 text-sm font-medium text-blue-500">
          <FaInfoCircle className="shrink-0" /> Your authenticator app will
          refresh 6 digit codes every 30 seconds.
        </div>
      ),
    },
    {
      title: "Sign in to Amazon",
      description: (
        <SignIn
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          setCurrentStep={setCurrentStep}
          goBackward={goBackward}
          code={code}
          setCode={setCode}
          codeValid={codeValid}
          setCodeValid={setCodeValid}
        />
      ),
      last: "true",
      desktopVideo: "",
      mobileVideo: "",
    },
    {
      extra: (
        <div>
          <div className="mx-auto -mt-9 w-60">
            <Lottie animationData={robot} loop={true} />
          </div>

          <h2 className="my-2.5 font-semibold">Attempting log in...</h2>
          <p className="">
            Please allow 1-3 minutes for full connection. You can click continue
            at any time.
          </p>
          <div className="mt-5 rounded-lg border p-5 text-left text-sm text-gray-700 shadow">
            <h3 className="mb-2.5 mt-0">Tips for success</h3>
            <p>
              • Expect at least 10 freebies a month, with many users getting
              their first within a week.
            </p>
            <p className="mt-2">
              • If you want more than just freebies, you can edit your account
              settings to set custom discount percentages and dollar amounts.
            </p>
            <p className="mt-2">
              • Contact customer support by pressing the widget in the bottom
              right anytime you need help.
            </p>
          </div>
          <button
            type="button"
            className="mt-5 flex w-full justify-center rounded-lg bg-gray-100 py-3.5 text-sm font-medium !text-black transition hover:bg-gray-200 active:bg-gray-300"
            onClick={() => {
              window.history.pushState({}, "", "/dashboard");
              window.location.reload();
            }}
          >
            Continue
          </button>
        </div>
      ),
    },
  ];

  return (
    <main className="gradient relative flex h-[calc(100vh-88px)] flex-col items-center gap-2.5  overflow-hidden overflow-y-scroll p-5 pb-32">
      {manualSetup === false ? (
        <Card
          key={currentStep}
          index={currentStep}
          title={steps[currentStep].title}
          video={steps[currentStep].video}
          description={steps[currentStep].description}
          list={steps[currentStep].list}
          manual={steps[currentStep].manual}
          goBackward={goBackward}
          goForward={goForward}
          currentStep={currentStep}
          first={steps[currentStep].first}
          last={steps[currentStep].last}
          setManualSetup={setManualSetup}
          mobileVideo={steps[currentStep].mobileVideo}
          desktopVideo={steps[currentStep].desktopVideo}
          extra={steps[currentStep].extra}
          code={code}
          setCode={setCode}
          codeValid={codeValid}
        />
      ) : (
        <ManualSetup
          setManualSetup={setManualSetup}
          code={code}
          setCode={setCode}
          codeValid={codeValid}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          setCurrentStep={setCurrentStep}
          setCodeValid={setCodeValid}
        />
      )}
    </main>
  );
}
