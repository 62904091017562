import clsx from "clsx";
import { useEffect, useState } from "react";

import {
  FaChevronLeft,
  FaChevronRight,
  FaExclamationCircle,
  FaPlus,
} from "react-icons/fa";

const Card = ({
  index,
  title,
  description,
  list,
  goForward,
  goBackward,
  first,
  last,
  setManualSetup,
  mobileVideo,
  desktopVideo,
  extra,
}) => {
  const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {
    setShowVideo(true);
  }, [index]);

  return (
    <div className="!text-neutral-800 prose w-full rounded-lg bg-white p-5 shadow-md lg:rounded-3xl lg:p-10">
      <div key={index} transition={{ type: "tween" }} className="prose">
        <h2 className="mb-2.5">{title}</h2>
        {mobileVideo && desktopVideo ? (
          <div>
            <button
              onClick={() => setShowVideo(!showVideo)}
              className="flex items-center justify-center gap-2.5 rounded-full border bg-white px-5 py-2 text-sm shadow-sm transition hover:bg-zinc-50/50 hover:shadow"
            >
              <p className="my-0 p-0">
                {showVideo === false ? "Click to see" : "Hide"} video tutorial
              </p>
              <FaPlus
                className={clsx(
                  "text-sm text-black transition",
                  showVideo === false ? "rotate-0" : "rotate-45"
                )}
              />
            </button>
            {showVideo ? (
              <>
                <video
                  controls
                  autoPlay
                  className="mt-2.5 rounded-md shadow-lg lg:hidden"
                  src={mobileVideo}
                ></video>
                <video
                  controls
                  autoPlay
                  className="mt-2.5 hidden aspect-square rounded-md object-cover object-top shadow-lg lg:block"
                  src={desktopVideo}
                ></video>
              </>
            ) : null}
          </div>
        ) : null}
        {description ? (
          <div className="flex w-full items-center gap-2.5">{description}</div>
        ) : null}
        {list ? (
          <ol>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ol>
        ) : null}
        {extra ? (
          <div className="mt-5 flex w-full items-center gap-2.5">{extra}</div>
        ) : null}
        <div
          className={clsx("flex justify-between gap-2.5", last ? null : "mt-5")}
        >
          {!first && !last && index !== 6 ? (
            <div className="flex w-full justify-between gap-2.5">
              <button
                onClick={goBackward}
                className="flex h-[36px] w-[36px] shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
              >
                <FaChevronLeft />
              </button>
              <button
                onClick={() => {
                  goForward();
                }}
                className="flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
              >
                Next <FaChevronRight className="text-xs" />
              </button>
            </div>
          ) : null}

          {first ? (
            <div className="flex w-full justify-between gap-2.5">
              <button
                onClick={() => setManualSetup(true)}
                className="flex items-center gap-1.5 rounded-full px-5 py-2 text-sm font-medium !text-gray-500  transition hover:bg-gray-100 hover:!text-gray-800 active:bg-gray-200"
              >
                <FaExclamationCircle /> I know my key
              </button>
              <button
                onClick={goForward}
                className="gradient flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium !text-white transition hover:-hue-rotate-15 active:-hue-rotate-30"
              >
                Get my key <FaChevronRight className="text-xs" />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Card;
